

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './navigation.css'
import {Logo} from '../../components';
import { databaseExistsUrl } from '../../libraries/fetching';
import {useEffect,useState} from 'react'
import {useParams} from 'react-router-dom'

const Navigation = () => {
  var signinurl="signin/";
  const [dbName,setDbName] = useState();
  const {databaseName} = useParams();
   
  useEffect(() => {
    var databaseName_ = {databaseName}.databaseName;
    console.log("useeffect dbname:" + databaseName_)

    // declare the data fetching function
    async function fetchDbName()
    {
      const data = await fetch(databaseExistsUrl + databaseName_);
      console.log(data);
      const jsondata = await data.json();
      console.log(jsondata);
      console.log(jsondata.Message);
      if (data.status === 200)
      {
        setDbName(databaseName_);
        //signinurl = "signin/" + databaseName_;
        window.localStorage.setItem('databaseName',databaseName_);
      }
      else
      {
        setDbName(databaseName_ + " finns inte hos BokaTid.nu!");
      }
    }

      
    if (databaseName_ === "signup")
    {
      databaseName_ = "";
    }
    else if (databaseName_ === undefined)
    {
      databaseName_ = "";
    }
    else
    {
        // call the function
      fetchDbName()
      // make sure to catch any error
      .catch(console.error);
      
    }
  },[databaseName]);

 
  
  function RenderSignInLink()
  {
    //Ska titta på om person är inloggad
    if (dbName !== "" && dbName !== undefined)
    {
      console.log(dbName);
      return <Nav.Link href={signinurl + dbName}>Logga in</Nav.Link>
    }
  }

  function RenderMyBookingsLink()
  {
    if (dbName !== "" && dbName !== undefined)
    {
      return <Nav.Link href="./mybookings">Mina bokningar</Nav.Link>
    }
  }

  function RenderRegistrationLink()
  {
    if (dbName === "" || dbName === undefined)
    {
      return <Nav.Link href="/signup">Anslut ditt företag</Nav.Link>
    }
   
  }

  function RenderUser()
  {
    return <Nav.Link href="./userprofile"> {window.localStorage.getItem('username')} </Nav.Link>
  }

  return (
    <Navbar className="navbar_container" expand="lg">
      <Container>
        <Navbar.Brand href="/"><Logo/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
           <h1>{dbName}</h1>
          </Nav>
          <Nav>
            {RenderSignInLink()}
            {RenderMyBookingsLink()}
            {RenderRegistrationLink()}
            {RenderUser()}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    
  )
 
}

export default Navigation