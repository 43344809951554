import React from 'react'
import './home.css'

const Home = () => {

  return (
    <div id="home">
        <h1>Startsida - Bokningsystemet BokaTid.nu</h1>
        <div>Homej sdfkjs fkjsf kjsd fks f jkslf ksjf jsf jks jfkj
        
        </div>
        
    </div>
  )
}

export default Home