import './bookingsystem.css'
//import { useParams } from 'react-router-dom'
//import clock from '../../assets/clock_corner_beige.jpg'



const Bookingsystem = () => {
  
    
  return (
    <>
    
    <div id="bookingsystem">
    
      <h1>Välj artikel/tjänst</h1>
      <h1>Välj objekt/resurs</h1>
      <h1>Välj tid</h1>
      
    </div>
    </>
  )
}

export default Bookingsystem