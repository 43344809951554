const defaultHeaders = {'Content-Type':'Application/json'};
const defaultUrlStart = "https://api.bokatid.nu/";
export const databaseExistsUrl = defaultUrlStart + "Auth/DatabaseExists?databaseName="

export async function apiRequest(url,methodIn,headersIn,bodyIn)
{
  url = defaultUrlStart + url;
  console.log("apiRequest url:" + url + ", method:" + methodIn + ", headers:" + headersIn + ", body:" + bodyIn);
  if (headersIn === null)
  {
    headersIn = defaultHeaders;
  }
  
  var apiOptions;

  if (bodyIn === null)
  {
    apiOptions = {
      method: methodIn,
      headers: headersIn
    }
  }
  else
  {
    apiOptions = {
      method: methodIn,
      headers: headersIn,
      body: JSON.stringify(bodyIn)
    }
  }
 
  
  const response = await fetch(url,apiOptions);
  const data = await response.json();

  console.log("Response status: " + response.status + " " + response.statusText)
  if (response.status === 200){
    console.log("Data:" + data.Message);
    console.log(response);
    return data;
  }
  else
  {
    console.error("API failed! Body:" + data.Message);
    console.log(response);
    return data;
  }
 
  
}

export function apiRequestNoAsync(url,methodIn,headersIn,bodyIn)
{
  url = defaultUrlStart + url;
  console.log("apiRequest url:" + url + ", method:" + methodIn + ", headers:" + headersIn + ", body:" + bodyIn);
  if (headersIn === null)
  {
    headersIn = defaultHeaders;
  }
  
  var apiOptions;

  if (bodyIn === null)
  {
    apiOptions = {
      method: methodIn,
      headers: headersIn
    }
  }
  else
  {
    apiOptions = {
      method: methodIn,
      headers: headersIn,
      body: JSON.stringify(bodyIn)
    }
  }
 
  
  const response = fetch(url,apiOptions);
  const data = response.json;

  console.log("Response status: " + response.status + " " + response.statusText)
  if (response.status === 200){
    console.log("Data:" + data.Message);
    console.log(response);
    return true;
  }
  else
  {
    console.error("API failed! Body:" + data.Message);
    console.log(response);
    return false;
  }
 
  
}

export async function apiBuildBodyFromFormId(formId)
{
  var body = "{";
  var count = document.getElementById(formId).elements.length; 
  console.log(count.toString() + " form:" + formId.toString())
  for (var i=0; i < count;i++)
  {
    //build body to send
   
    if (document.getElementById(formId).elements[i].name !== "" && document.getElementById(formId).elements[i].value !== "")
    {
       body += '"' + document.getElementById(formId).elements[i].name + '":"' + document.getElementById(formId).elements[i].value + '",';
    }
   
    console.log("Element:" + i.toString() )
  }
  body += '"Status":"Done"';
  body += "}";
  console.log(body)
  return JSON.parse(body);
}