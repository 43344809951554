import React from 'react'
import './logo.css'
import logo from '../../assets/favicon-color.png'
import logo_svg from '../../assets/logo-no-background.png'

const Logo = () => {
  return (
    <div>
      <img id="logo" src={logo} alt="logo" className='rotate'></img>&nbsp;
      <img id="logo_text" src={logo_svg} alt="logo text"></img>
    </div>

)
}

export default Logo