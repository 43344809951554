import React from 'react'
import { useParams } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import './signin.css'
import { apiRequest, apiBuildBodyFromFormId} from '../../libraries/fetching';

const Signin = () => {
  const {databaseName} = useParams();

  async function onSubmitHandler()
  {
    const body = await apiBuildBodyFromFormId('formSignin');
    const data = await apiRequest("Auth/GetToken","POST",{'Content-Type':'Application/json'},body);
    console.log(data.Token);
    console.log(document.getElementById('remember').checked);
    window.localStorage.setItem('databaseName',databaseName);
    window.localStorage.setItem('token',data.Token);
    window.localStorage.setItem('remember',document.getElementById('remember').checked);
    window.localStorage.setItem('username',document.getElementById('username').value);
    window.location.href='../' + databaseName;
  }
  //GetToken med username,password,databaseName
  return (
    <div id="signin">
      <h1>Inloggning {databaseName}</h1>
    <Form id='formSignin'>
    <Form.Group className="mb-3" controlId="formDbName">
        <Form.FloatingLabel controlId="username" label="Användarnamn">
          <Form.Control type="text" name="username" placeholder="Ange önskat användarnamn" />
        </Form.FloatingLabel>
       
      </Form.Group>

      <Form.Group className="mb-3" controlId="formPassword">
        <Form.FloatingLabel controlId="password" label="Lösenord">
          <Form.Control type="password" name="password" placeholder="Password" />
        </Form.FloatingLabel>
      </Form.Group>
    
  
    <Form.Group className="mb-3" controlId="formCheckbox">
      <Form.Check type="checkbox" id="remember" label="Kom ihåg mig" />
    </Form.Group>

   
      <input type="hidden" value={databaseName} name="databaseName" id="databaseName" />
   
    <Button variant="primary" onClick={onSubmitHandler}>
      Logga in
    </Button>
</Form>
</div>
  )
}

export default Signin