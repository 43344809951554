import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import './signup.css'

import { apiRequest, apiBuildBodyFromFormId} from '../../libraries/fetching';



const Signup = () => {

  async function onSubmitHandler()
  {
    const body = await apiBuildBodyFromFormId('formSignup');
    await apiRequest("Auth/Register","POST",{'Content-Type':'Application/json'},body);
  }

  return (
    <div id="signup">
    <h1>Skapa mitt bokningssytem</h1>
    <Form id='formSignup'>
      <Form.Group className="mb-3" controlId="formCompanyname">
        <Form.FloatingLabel controlId="Companyname" label="Företagsnamn">
          <Form.Control type="text" name="companyName" placeholder="Ange ditt företagsnamn" />
        </Form.FloatingLabel>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formEmail">
        <Form.FloatingLabel controlId="Email" label="E-postadress">
          <Form.Control type="email" name="username" placeholder="Ange e-postadress" />
        </Form.FloatingLabel>
        <Form.Text className="text-muted">
          <i>*Vi delar inte din e-postadress med någon annan.</i>
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formDbName">
        <Form.FloatingLabel controlId="dbName" label="Användarnamn">
          <Form.Control type="text" name='databaseName' placeholder="Ange önskat användarnamn" />
        </Form.FloatingLabel>
        <Form.Text className="text-muted">
          <i>*Ditt användarnamn blir också en del av din unika url sökväg: https://app.bokatid.nu/[användarnamn]</i>
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formPassword">
        <Form.FloatingLabel controlId="Password" label="Lösenord">
          <Form.Control type="password" name="password" placeholder="Password" />
        </Form.FloatingLabel>
      </Form.Group>
     
      <Button variant="primary" onClick={onSubmitHandler}>
        Registrera mitt företag
      </Button>
  </Form>
  
  </div>
  )
}

export default Signup