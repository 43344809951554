
import './header.css'
import {Navigation} from '../../components';

const header = () => {
  return (
    <section className='Header'>
        <section className='Header-top'><Navigation/></section>
        <section><hr className='hr-line'/></section>
    </section>
  )
}

export default header