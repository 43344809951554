import React from 'react'
import './footer.css'
import {Logo} from '../../components';


const footer = () => {
  return (
    <section className="footer_container">
      <section><hr className='hr-line'/></section>
      <footer className="d-flex align-items-center my-4">
        <p className="col-md-4 mb-0 text-muted">&copy; 2023 Powered by Digishop<br/>Skaftgatan 5, 256 56 Helsingborg</p>
    
        <a href="/" className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto text-decoration-none">
          <Logo/>
        </a>

        <p className="col-md-4 mb-0 text-muted">Text</p>
        
      </footer>
    </section>
  )
}

export default footer