import { Route,Routes} from "react-router-dom"
import './App.css';

import {Header,Footer,Home,Signin,Signup,Bookingsystem} from './containers';

import 'bootstrap/dist/css/bootstrap.min.css';


const App = () => {
  
  return (
    <>
    <Routes>
      <Route path="/:databaseName" element={<Header/>}/>
      <Route path="/signin/:databaseName" element={<Header/>}/>
      <Route path="/" element={<Header/>}/>
    </Routes>
    
    <div className="main_container">
        <Routes>
       
        <Route path="/" element={<Home/>} />
        <Route path="/:databaseName" element={<Bookingsystem/>} />
        
        <Route path="/signup/" element={<Signup/>} />
        <Route path="/signin/:databaseName" element={<Signin/>} />
      
      </Routes> 
    </div> 
    <Footer/>
   
    </>
  );
}

export default App;
